<template>

  <p style="color: rgb(223, 223, 223); height: 550px; padding: 25px; margin-top: 50px; text-align: center; font-size: .9em;">
    Chrysler, Jeep, Dodge, Ram and Fiat are registered trademarks of FCA US LLC. © {{ year }} FCA US LLC. All rights reserved.<br><br>
    FCA email headquarters, FCA US LLC, CIMS 485-00-44, 1000 Chrysler Drive, Auburn Hills, MI 48326.<br>
    At FCA US LLC, we value your privacy. If you do not wish to receive further email communications from FCA US LLC

  </p> 

</template>

<script>
export default {

  name: "the-footer",

  methods: {
  },

  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    }
  }
};

</script>

<style scoped>

</style>