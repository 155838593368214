<template>
  <!-- default container -->
  <div>
    
    <p style="margin-top: 100px; margin-bottom: 400px; width: 100%; text-align: center; font-weight: 600; font-size: 1.5em; color: white;">
      Page not found.
    </p>

    <the-footer></the-footer>

  </div>
  <!-- end default container -->
</template>

<script>

import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'NotFound',
  components: {
    TheFooter,
  },

  watch: {

  },

  created() {
    
  },

  data() {
    return {
      
    };
  },

  methods: {

    setPageTitle() {
      document.title = 'Dealer not found.';
    },

  },

  computed: {

  },

};
</script>